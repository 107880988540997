<template>
	<div id="navigation">
		<v-app-bar app color="white" dark clipped-left :class="[environment]">
			<v-app-bar-nav-icon light @click.stop="drawer = !drawer" :disabled="disabledMenu" />
			<router-link class="d-flex align-center" to="/info">
				<v-img
					:src="require('@/assets/logo_head.svg')"
					class="my-3"
					contain
					height="40"
					:width="imgWidth"
				/>
			</router-link>
			<v-toolbar-title class="grey--text text--darken-2 d-none d-sm-block">
				{{ $t('app.title') }}
			</v-toolbar-title>

			<v-spacer />
			<div v-if="!isMobile">
				<v-menu bottom left transition="slide-y-transition" offset-y>
					<template v-slot:activator="menuActivator">
						<v-tooltip bottom>
							<template v-slot:activator="tooltipActivator">
								<v-btn
									icon
									v-bind="menuActivator.attrs"
									v-on="{ ...menuActivator.on, ...tooltipActivator.on }"
									:max-width="24"
									class="mx-2"
								>
									<v-icon>mdi-dark mdi-earth</v-icon>
								</v-btn>
							</template>
							<span>{{ $t('app.language') }}</span>
						</v-tooltip>
					</template>

					<v-list>
						<v-subheader>{{ $t('app.language') }}</v-subheader>
						<v-list-item-group>
							<v-list-item v-for="(language, i) in languages" :key="i">
								<v-list-item-content @click="changeLocale(language.locale)">
									<v-list-item-title>{{ language.label }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-menu>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							icon
							v-bind="attrs"
							v-on="on"
							@click.stop="selectOrganization = !selectOrganization"
							:max-width="24"
							class="mx-2"
							:disabled="disabledMenu"
						>
							<v-icon>mdi-dark mdi-lan</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('menus.organization_list.tooltip') }}</span>
				</v-tooltip>
			</div>
			<v-menu bottom left transition="slide-y-transition" offset-y>
				<template v-slot:activator="menuActivator">
					<v-tooltip bottom>
						<template v-slot:activator="tooltipActivator">
							<v-btn
								icon
								v-bind="menuActivator.attrs"
								v-on="{ ...menuActivator.on, ...tooltipActivator.on }"
								:max-width="24"
								class="mx-2"
							>
								<v-avatar v-if="!!user.picture" size="24">
									<v-img :src="user.picture" alt="user picture" contain />
								</v-avatar>
								<v-avatar v-else>
									<v-icon>mdi-dark mdi-account-circle</v-icon>
								</v-avatar>
							</v-btn>
						</template>
						<span>{{ $t('app.account_info') }}</span>
					</v-tooltip>
				</template>

				<v-list width="300">
					<v-list-item>
						<v-list-item-icon>
							<v-img
								v-if="!!user.picture"
								:src="user.picture"
								alt="user picture"
								max-width="60"
								class="rounded-circle"
								contain
							/>
							<v-icon v-else size="60">mdi-dark mdi-account-circle</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title class="text-h5">{{ user.name }}</v-list-item-title>
							<v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-divider />
					<div v-if="isMobile">
						<v-list-item @click.stop="selectLanguage = !selectLanguage">
							<v-list-item-icon>
								<v-icon>mdi-dark mdi-earth</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{ $t('app.language') }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item @click.stop="selectOrganization = !selectOrganization">
							<v-list-item-icon>
								<v-icon>mdi-dark mdi-lan</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{ $t('menus.organization_list.tooltip') }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</div>
					<v-list-item @click.stop="logout">
						<v-list-item-icon>
							<v-icon>mdi-logout-variant</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>{{ $t('point_reader.logout.menu') }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>

		<v-navigation-drawer v-if="!disabledMenu" v-model="drawer" color="white" app clipped bottom>
			<v-list dense>
				<v-list-item-group active-class="list-item" v-model="selected" max="1" color="red">
					<v-list-item
						link
						class="list-item"
						v-for="menu in menus"
						:key="menu.route"
						:to="to(menu.route)"
						extract
					>
						<v-list-item-action>
							<v-icon>{{ menu.icon }}</v-icon>
						</v-list-item-action>
						<v-tooltip bottom open-delay="1250">
							<template v-slot:activator="{ on, attrs }">
								<v-list-item-content v-bind="attrs" v-on="on">
									<v-list-item-title>{{ $t(menu.title) }}</v-list-item-title>
								</v-list-item-content>
							</template>
							<span>{{ $t(menu.tooltip) }}</span>
						</v-tooltip>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
		<v-navigation-drawer
			v-if="!disabledMenu"
			v-model="selectOrganization"
			color="white"
			app
			bottom
			right
			temporary
		>
			<v-card elevation="0">
				<v-card-subtitle> {{ selectedOrganization.name }} </v-card-subtitle>
				<v-card-text>
					<OrganizationLogo
						class="d-flex justify-center"
						:organization-logo="selectedOrganization.logo"
						contain
					/>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-text class="pa-0">
					<v-list dense>
						<v-subheader class="px-4">{{ $t('organization_list.another') }}</v-subheader>
						<v-list-item
							v-for="(organization, i) in otherOrganizations"
							:key="i"
							@click="changeOrganization(organization)"
							class="px-8"
						>
							<v-list-item-content>
								<v-list-item-title>{{ organization.name }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="isBusinessUser" class="px-8" @click="addOrganization">
							<v-list-item-icon class="mr-0">
								<v-icon> mdi-plus </v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title> {{ $t('actions.add_organization') }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card-text>
			</v-card>
		</v-navigation-drawer>
		<v-navigation-drawer
			v-if="!disabledMenu"
			v-model="selectLanguage"
			color="white"
			app
			bottom
			right
			temporary
		>
			<v-list>
				<v-subheader>{{ $t('app.language') }}</v-subheader>
				<v-list-item-group>
					<v-list-item v-for="(language, i) in languages" :key="i">
						<v-list-item-content @click="changeLocale(language.locale)">
							<v-list-item-title>{{ language.label }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>
<script>
import axios from 'axios';

import envIdentify from '@/lib/env-identify';
import OrganizationLogo from '@/components/OrganizationLogo.vue';
import validateOrganizationId from '@/lib/validate-organization-id';

export default {
	name: 'NavigationHeader',
	components: { OrganizationLogo },
	props: {
		selectedOrganization: {
			type: Object,
			default: () => {},
			required: true
		},
		disabledMenu: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data: () => ({
		environment: 'env-production',
		drawer: true,
		selected: null,
		menus: [],
		languages: [
			{
				locale: 'ja',
				label: '日本語'
			},
			{
				locale: 'en',
				label: 'English'
			}
		],
		selectOrganization: false,
		selectLanguage: false,
		allMenus: [
			{
				route: '/info',
				icon: 'mdi-domain',
				title: 'menus.organization_info.title',
				tooltip: 'menus.organization_info.tooltip',
				changeInfo: true
			},
			{
				route: '/users',
				icon: 'mdi-account-multiple',
				title: 'menus.organization_users.title',
				tooltip: 'menus.organization_users.tooltip'
			},
			{
				route: '/invite',
				icon: 'mdi-email-newsletter',
				title: 'menus.invite_org.title',
				tooltip: 'menus.invite_org.tooltip'
			}
			// Todo イベント駆動によるデータ連動ができるようになるまで組織削除のページは使用不可
			// {
			// 	route: '/delete',
			// 	icon: 'mdi-delete',
			// 	title: 'menus.delete.title',
			// 	tooltip: 'menus.delete.tooltip',
			// 	deletable: true
			// }
		]
	}),
	computed: {
		user() {
			return this.$store.getters.user;
		},
		isBusinessUser() {
			return this.user.accountType === 'business';
		},
		organizations() {
			return this.$store.getters.organizations;
		},
		otherOrganizations() {
			return this.organizations.filter(
				(organization) => organization.id !== this.selectedOrganization.id
			);
		},
		isPrimaryOwner() {
			return this.user.accountId === this.selectedOrganization.primaryOwnerAccountId;
		},
		to() {
			return (path) =>
				validateOrganizationId(this.$route.params.id)
					? `${path}/${validateOrganizationId(this.$route.params.id)}`
					: path;
		},
		isMobile() {
			return this.$vuetify.breakpoint.xs;
		},
		imgWidth() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 180;
				default:
					return 220;
			}
		}
	},
	watch: {
		organizations() {
			this.init();
		},
		selectedOrganization() {
			this.init();
		}
	},
	created() {
		this.init();
		this.changeLocale(this.user.locale);
		this.environment = envIdentify();
	},
	methods: {
		init() {
			this.menus.length = 0;
			this.allMenus
				.filter((menu) => !menu.deletable || this.isPrimaryOwner)
				.filter((menu) => !menu.changeInfo || this.isBusinessUser)
				.forEach((menu) => this.menus.push(menu));

			this.menus.forEach((menu, index) => {
				if (menu.route === this.$route.path) this.selected = index;
			});
		},
		changeLocale(locale) {
			this.$i18n.locale = locale;
			this.$vuetify.lang.current = locale;
			switch (locale) {
				case 'en':
					document.getElementsByTagName('html').item(0).lang = 'en';
					break;
				default:
					this.$i18n.locale = 'ja';
					this.$vuetify.lang.current = 'ja';
					document.getElementsByTagName('html').item(0).lang = 'ja';
					break;
			}
			this.selectLanguage = false;
		},
		addOrganization() {
			this.$router.push({ path: '/new' });
		},
		changeOrganization(changeOrganization) {
			this.$emit('switchSelectedOrganization', changeOrganization);
			this.selectOrganization = false;
		},
		async logout() {
			const {
				data: { logoutUrl }
			} = await axios.get(`/logout/`, {
				params: {
					ui_locales: this.$i18n.locale
				}
			});

			window.open(logoutUrl, '_self');
		}
	}
};
</script>
<style lang="sass" scoped>
#navigation::v-deep
	.list-item
		border-left: solid 3px transparent
		&.v-list-item--active
			border-left: solid 3px #D70027
	.red_list .v-list-item-group .v-list-item--active
		background-color: red
		color: white

.env-template
	&::after
		text-align: center
		position: absolute
		border-radius: 0 0 5px 5px
		height: 16px
		left: calc(50% - 50px)
		color: white
		font-size: 6px
		width: 100px

header.env-local
		border-bottom: 3px solid rgba(128, 128, 128, 0.8) !important
		&::after
			@extend .env-template
			content: 'Local Development'
			background-color:  rgba(128, 128, 128, 0.8)
header.env-dev
		border-bottom: 3px solid rgba(69, 90, 100, 0.8) !important
		&::after
			@extend .env-template
			content: 'AWS Development'
			background-color:  rgba(69, 90, 100, 0.8)
header.env-sandbox
		border-bottom: 3px solid rgba(255, 160, 0, 0.8) !important
		&::after
			@extend .env-template
			content: 'Sandbox'
			background-color:  rgba(255, 160, 0, 0.8)
header.env-green
		border-bottom: 3px solid rgb(46, 125, 50, 0.8) !important
		&::after
			@extend .env-template
			content: 'Production/Green'
			background-color:  rgba(46, 125, 50, 0.8)
</style>
