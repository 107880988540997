import Vue from 'vue';
import VueRx from 'vue-rx';

import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';

import i18n from '@/plugins/i18n';
import App from '@/pages/App.vue';
import router from '@/pages/router';
import vuetify from '@/plugins/vuetify';
import store from '@/store';

Vue.config.productionTip = false;
Vue.use(VueTelInputVuetify, {
	vuetify
});
Vue.use(VueRx);

new Vue({
	router,
	vuetify,
	i18n,
	render: (h) => h(App),
	store,
	beforeCreate() {
		this.$store.dispatch('loadLocalStorage');
	}
}).$mount('#app');
