import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import Store from '@/store/';
import customAxios from '@/plugins/custom-axios';
import qs from 'qs';

import HomeView from '@/pages/views/HomeView.vue';
import InviteOrg from '@/pages/views/InviteOrganization.vue';
import OrganizationInfo from '@/pages/views/OrganizationInfo.vue';
import OrganizationUsers from '@/pages/views/OrganizationUsers.vue';
import NewOrganization from '@/pages/views/NewOrganization.vue';
// import DeleteOrganization from '@/pages/views/DeleteOrganization.vue';
import UnexpectedError from '@/pages/views/UnexpectedError.vue';
import NotLogined from '@/pages/views/NotLogined.vue';
import NotFound from '@/pages/views/NotFound.vue';
import NoAuth from '@/pages/views/NoAuth.vue';

import validateOrganizationId from '@/lib/validate-organization-id';
import envIdentify from '@/lib/env-identify';
import VueGtag from 'vue-gtag';

Vue.use(VueRouter);

const routes = [
	{
		path: '/login',
		// eslint-disable-next-line no-unused-vars
		redirect: (to) => {
			const { url } = to.query;
			window.location.href = url ? `/login/?${qs.stringify({ url })}` : '/login/';
		}
	},
	{
		path: '/error',
		component: UnexpectedError
	},
	{
		path: '/notlogin',
		component: NotLogined
	},
	{
		path: '/',
		name: 'home',
		meta: { requiresAuth: true },
		component: HomeView,
		children: [
			{
				path: 'info/:id(\\d+)?',
				name: 'info',
				props: (route) => ({ id: validateOrganizationId(route.params.id) }),
				component: OrganizationInfo
			},
			{
				path: 'invite/:id(\\d+)?',
				name: 'invite',
				props: (route) => ({ id: validateOrganizationId(route.params.id) }),
				component: InviteOrg
			},
			{
				path: 'users/:id(\\d+)?',
				name: 'users',
				props: (route) => ({ id: validateOrganizationId(route.params.id) }),
				component: OrganizationUsers
			},
			{
				path: 'new',
				name: 'new',
				component: NewOrganization
			},
			// Todo イベント駆動によるデータ連動ができるようになるまで組織削除のページは使用不可
			// {
			// 	path: 'delete/:id(\\d+)?',
			// 	name: 'delete',
			// 	props: (route) => ({ id: validateOrganizationId(route.params.id) }),
			// 	component: DeleteOrganization
			// },
			{
				path: '/notfound',
				name: 'notFound',
				component: NotFound
			},
			{
				path: '/noauth',
				name: 'noauth',
				component: NoAuth
			},
			{
				path: '*',
				beforeEnter: (to, from, next) => {
					next('info');
				}
			}
		]
	}
];

const router = new VueRouter({
	mode: 'history',
	routes
});

router.beforeEach(async (to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		try {
			const {
				data: { token: accountToken }
			} = await axios.get(`/login/token`);

			Store.dispatch('initAccountToken', accountToken);
			Vue.prototype.$axios = customAxios(accountToken);

			const organizationId = validateOrganizationId(to.params.id);
			try {
				if (!Object.keys(Store.state.user).length) {
					const { data: user } = await Vue.prototype.$axios.oidc.get(`/api/v1/user`);
					Store.dispatch('setUser', user);
				}

				const {
					data: { organizations }
				} = await Vue.prototype.$axios.oidc.get(`/api/v1/organizations`, {
					params: {
						only_privileged: true
					}
				});
				Store.dispatch('setOrganizations', organizations);
			} catch (error) {
				next('/error');
			}

			if (!Store.state.organizations.length) {
				if (to.name !== 'noauth' && Store.state.user.accountType !== 'business') {
					Store.dispatch('clearLocalStorage');
					next('/noauth');
				}

				if (to.name !== 'new' && Store.state.user.accountType === 'business') {
					Store.dispatch('clearLocalStorage');
					next('/new');
				}
			}

			if (
				to.name !== 'notFound' &&
				organizationId &&
				!Store.state.organizations.some((org) => org.id === organizationId)
			) {
				Store.dispatch('clearLocalStorage');
				next('/notfound');
			}

			if (to.name !== 'users' && to.name === 'info' && Store.state.user.accountType !== 'business')
				next('/users');
		} catch (e) {
			next({ path: '/login', query: { url: to.path } });
		}
	}
	next();
});

Vue.use(
	VueGtag,
	{
		config: { id: envIdentify() === 'env-production' ? 'G-NGMM8FS6DD' : 'UA-DUMY123-1' }
	},
	router
);

export default router;
