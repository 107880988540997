<template>
	<v-container>
		<v-row class="justify-center">
			<v-col cols="12" ms="8" md="8" lg="8" xl="8" class="pa-1 pa-sm-3 pr-sm-1">
				<v-card elevation="1" class="ma-sm-3 mr-sm-0">
					<v-card-title> {{ $t('no_auth.title') }} </v-card-title>
					<v-card-actions>
						<v-spacer />
						<v-btn color="primary" @click="logout" min-width="100px">
							{{ $t('no_auth.back') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from 'axios';

export default {
	name: 'NoAuth',
	methods: {
		async logout() {
			const {
				data: { logoutUrl }
			} = await axios.get(`/logout/`);

			window.open(logoutUrl, '_self');
		}
	}
};
</script>
