<template>
	<v-select
		:value="value"
		@input="onInput"
		:items="numberOfEmployees"
		:disabled="disabled"
		:clearable="clearable"
		:rules="rules"
		:item-text="itemText"
		:label="label"
		item-value="id"
		prepend-icon="mdi-account-multiple"
	/>
</template>
<script>
export default {
	props: {
		value: {
			type: Number,
			default: null,
			required: false
		},
		numberOfEmployees: {
			type: Array,
			default: () => [],
			required: false
		},
		label: {
			type: String,
			default: 'Number of employees',
			required: false
		},
		clearable: {
			type: Boolean,
			default: true,
			required: false
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	i18n: {
		messages: {
			en: {
				labels: {
					number_of_employees: 'Number of employees, including you'
				},
				number_of_employees_select: {
					just_you: 'Just you',
					'2-9': '2-9',
					'10-99': '10-99',
					'100-299': '100-299',
					'300+': '300+'
				}
			},
			ja: {
				labels: {
					number_of_employees: '従業員数'
				},
				number_of_employees_select: {
					just_you: '経営者のみ',
					'2-9': '2～9名',
					'10-99': '10～99名',
					'100-299': '100～299名',
					'300+': '300名～'
				}
			}
		}
	},
	computed: {
		rules() {
			return [(v) => !!v || this.$t('errors.required', { key: this.label })];
		},
		itemText() {
			return (item) => this.$t(`number_of_employees_select.${item.i18n_label}`);
		}
	},
	methods: {
		onInput(value) {
			this.$emit(`input`, value);
		}
	}
};
</script>
