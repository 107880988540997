<template>
	<v-container>
		<v-row>
			<v-col cols="12" sm="8" md="8" lg="8" xl="8" class="pa-1 pa-sm-3">
				<v-card elevation="1" class="ma-sm-3 mr-sm-0">
					<v-form v-model="valid" ref="form" @submit.prevent>
						<v-card-title>
							{{ $t('organization.title') }}
						</v-card-title>
						<v-alert
							border="left"
							type="error"
							dense
							v-if="errorMessage"
							style="font-size: smaller"
							class="ma-3"
						>
							{{ errorMessage }}
						</v-alert>
						<v-card-text>
							<v-container>
								<OrganizationContent
									v-model="organization"
									:number-of-employees="numberOfEmployees"
									validate-on-blur-industry-selector
									create
								/>
							</v-container>
						</v-card-text>
					</v-form>
					<v-card-actions>
						<v-spacer />
						<v-btn
							v-if="belongToOrganization"
							color="secondary"
							min-width="100px"
							@click="backInfo()"
						>
							{{ $t('actions.cancel') }}
						</v-btn>
						<v-btn
							color="primary"
							min-width="100px"
							:disabled="!valid"
							:loading="loading"
							@click="submit()"
						>
							{{ $t('actions.create') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import OrganizationContent from '@/components/OrganizationContent.vue';

export default {
	name: 'NewOrganization',
	components: {
		OrganizationContent
	},
	data: () => ({
		valid: false,
		organization: {
			name: '',
			phoneNumber: '',
			industryCode: null,
			countryCode: '',
			numberOfEmployeeId: null,
			website: null
		},
		numberOfEmployees: [],
		loading: false,
		errorMessage: '',
		onlyCountries: ['cn', 'hk', 'jp', 'kr', 'tw', 'us', 'vn']
	}),
	async created() {
		await this.init();
	},
	computed: {
		belongToOrganization() {
			return !!this.$store.getters.organizations.length;
		}
	},
	methods: {
		async countryChanged(data) {
			this.organization.countryCode = data.iso2.toLowerCase();
		},
		async init() {
			try {
				const {
					data: { employees }
				} = await this.$axios.oidc.get(`/api/v2/private/employees`);
				employees.forEach((employee) => this.numberOfEmployees.push(employee));
			} catch (e) {
				this.errorMessage = 'unknown';
			}
		},
		async submit() {
			try {
				const { data } = await this.$axios.oidc.post(`/api/v1/organization`, this.organization);
				const organization = data;
				this.$store.dispatch('saveLocalStorage', organization.id);

				const {
					data: { organizations }
				} = await this.$axios.oidc.get(`/api/v1/organizations`);
				this.$store.dispatch('setOrganizations', organizations);

				this.$router.push({ path: `/info/${organization.id}` });
			} catch (e) {
				switch (e?.response?.status) {
					case 403:
						this.errorMessage = this.$t('errors.organization_create');
						break;
					default:
						this.errorMessage = this.$t('errors.unknown');
						break;
				}
			}
		},
		backInfo() {
			this.$router.push({ path: '/info' });
		}
	}
};
</script>
