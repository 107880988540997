export const GICS_EN = {
	10: 'Energy',
	15: 'Materials',
	20: 'Industrials',
	25: 'Consumer Discretionary',
	30: 'Consumer Staples',
	35: 'Health Care',
	40: 'Financials',
	45: 'Information Technology',
	50: 'Communication Services',
	55: 'Utilities',
	60: 'Real Estate',
	1010: 'Energy',
	1510: 'Materials',
	2010: 'Capital Goods',
	2020: 'Commercial & Professional Services',
	2030: 'Transportation',
	2510: 'Automobiles & Components',
	2520: 'Consumer Durables & Apparel',
	2530: 'Consumer Services',
	2550: 'Retailing',
	3010: 'Food & Staples Retailing',
	3020: 'Food, Beverage & Tobacco',
	3030: 'Household & Personal Products',
	3510: 'Health Care Equipment & Services',
	3520: 'Pharmaceuticals, Biotechnology & Life Sciences',
	4010: 'Banks',
	4020: 'Diversified Financials',
	4030: 'Insurance',
	4510: 'Software & Services',
	4520: 'Technology Hardware & Equipment',
	4530: 'Semiconductors & Semiconductor Equipment',
	5010: 'Communication Services',
	5020: 'Media & Entertainment',
	5510: 'Utilities',
	6010: 'Real Estate',
	101010: 'Energy Equipment & Services',
	101020: 'Oil, Gas & Consumable Fuels',
	151010: 'Chemicals',
	151020: 'Construction Materials',
	151030: 'Containers & Packaging',
	151040: 'Metals & Mining',
	151050: 'Paper & Forest Products',
	201010: 'Aerospace & Defense',
	201020: 'Building Products',
	201030: 'Construction & Engineering',
	201040: 'Electrical Equipment',
	201050: 'Industrial Conglomerates',
	201060: 'Machinery',
	201070: 'Trading Companies & Distributors',
	202010: 'Commercial Services & Supplies',
	202020: 'Professional Services',
	203010: 'Air Freight & Logistics',
	203020: 'Airlines',
	203030: 'Marine',
	203040: 'Road & Rail',
	203050: 'Transportation Infrastructure',
	251010: 'Auto Components',
	251020: 'Automobiles',
	252010: 'Household Durables',
	252020: 'Leisure Products',
	252030: 'Textiles, Apparel & Luxury Goods',
	253010: 'Hotels, Restaurants & Leisure',
	253020: 'Diversified Consumer Services',
	255010: 'Distributors',
	255020: 'Internet & Direct Marketing Retail',
	255030: 'Multiline Retail',
	255040: 'Specialty Retail',
	301010: 'Food & Staples Retailing',
	302010: 'Beverages',
	302020: 'Food Products',
	302030: 'Tobacco',
	303010: 'Household Products',
	303020: 'Personal Products',
	351010: 'Health Care Equipment & Supplies',
	351020: 'Health Care Providers & Services',
	351030: 'Health Care Technology',
	352010: 'Biotechnology',
	352020: 'Pharmaceuticals',
	352030: 'Life Sciences Tools & Services',
	401010: 'Banks',
	401020: 'Thrifts & Mortgage Finance',
	402010: 'Diversified Financial Services',
	402020: 'Consumer Finance',
	402030: 'Capital Markets',
	402040: 'Mortgage Real Estate Investment Trusts (REITs)',
	403010: 'Insurance',
	451020: 'IT Services',
	451030: 'Software',
	452010: 'Communications Equipment',
	452020: 'Technology Hardware, Storage & Peripherals',
	452030: 'Electronic Equipment, Instruments & Components',
	453010: 'Semiconductors & Semiconductor Equipment',
	501010: 'Diversified Telecommunication Services',
	501020: 'Wireless Telecommunication Services',
	502010: 'Media',
	502020: 'Entertainment',
	502030: 'Interactive Media & Services',
	551010: 'Electric Utilities',
	551020: 'Gas Utilities',
	551030: 'Multi-Utilities',
	551040: 'Water Utilities',
	551050: 'Independent Power and Renewable Electricity Producers',
	601010: 'Equity Real Estate Investment Trusts (REITs)',
	601020: 'Real Estate Management & Development',
	10101010: 'Oil & Gas Drilling',
	10101020: 'Oil & Gas Equipment & Services',
	10102010: 'Integrated Oil & Gas',
	10102020: 'Oil & Gas Exploration & Production',
	10102030: 'Oil & Gas Refining & Marketing',
	10102040: 'Oil & Gas Storage & Transportation',
	10102050: 'Coal & Consumable Fuels',
	15101010: 'Commodity Chemicals',
	15101020: 'Diversified Chemicals',
	15101030: 'Fertilizers & Agricultural Chemicals',
	15101040: 'Industrial Gases',
	15101050: 'Specialty Chemicals',
	15102010: 'Construction Materials',
	15103010: 'Metal & Glass Containers',
	15103020: 'Paper Packaging',
	15104010: 'Aluminum',
	15104020: 'Diversified Metals & Mining',
	15104025: 'Copper',
	15104030: 'Gold',
	15104040: 'Precious Metals & Minerals',
	15104045: 'Silver',
	15104050: 'Steel',
	15105010: 'Forest Products',
	15105020: 'Paper Products',
	20101010: 'Aerospace & Defense',
	20102010: 'Building Products',
	20103010: 'Construction & Engineering',
	20104010: 'Electrical Components & Equipment',
	20104020: 'Heavy Electrical Equipment',
	20105010: 'Industrial Conglomerates',
	20106010: 'Construction Machinery & Heavy Trucks',
	20106015: 'Agricultural & Farm Machinery',
	20106020: 'Industrial Machinery',
	20107010: 'Trading Companies & Distributors',
	20201010: 'Commercial Printing',
	20201050: 'Environmental & Facilities Services',
	20201060: 'Office Services & Supplies',
	20201070: 'Diversified Support Services',
	20201080: 'Security & Alarm Services',
	20202010: 'Human Resource & Employment Services',
	20202020: 'Research & Consulting Services',
	20301010: 'Air Freight & Logistics',
	20302010: 'Airlines',
	20303010: 'Marine',
	20304010: 'Railroads',
	20304020: 'Trucking',
	20305010: 'Airport Services',
	20305020: 'Highways & Railtracks',
	20305030: 'Marine Ports & Services',
	25101010: 'Auto Parts & Equipment',
	25101020: 'Tires & Rubber',
	25102010: 'Automobile Manufacturers',
	25102020: 'Motorcycle Manufacturers',
	25201010: 'Consumer Electronics',
	25201020: 'Home Furnishings',
	25201030: 'Homebuilding',
	25201040: 'Household Appliances',
	25201050: 'Housewares & Specialties',
	25202010: 'Leisure Products',
	25203010: 'Apparel, Accessories & Luxury Goods',
	25203020: 'Footwear',
	25203030: 'Textiles',
	25301010: 'Casinos & Gaming',
	25301020: 'Hotels, Resorts & Cruise Lines',
	25301030: 'Leisure Facilities',
	25301040: 'Restaurants',
	25302010: 'Education Services',
	25302020: 'Specialized Consumer Services',
	25501010: 'Distributors',
	25502020: 'Internet & Direct Marketing Retail',
	25503010: 'Department Stores',
	25503020: 'General Merchandise Stores',
	25504010: 'Apparel Retail',
	25504020: 'Computer & Electronics Retail',
	25504030: 'Home Improvement Retail',
	25504040: 'Specialty Stores',
	25504050: 'Automotive Retail',
	25504060: 'Homefurnishing Retail',
	30101010: 'Drug Retail',
	30101020: 'Food Distributors',
	30101030: 'Food Retail',
	30101040: 'Hypermarkets & Super Centers',
	30201010: 'Brewers',
	30201020: 'Distillers & Vintners',
	30201030: 'Soft Drinks',
	30202010: 'Agricultural Products',
	30202030: 'Packaged Foods & Meats',
	30203010: 'Tobacco',
	30301010: 'Household Products',
	30302010: 'Personal Products',
	35101010: 'Health Care Equipment',
	35101020: 'Health Care Supplies',
	35102010: 'Health Care Distributors',
	35102015: 'Health Care Services',
	35102020: 'Health Care Facilities',
	35102030: 'Managed Health Care',
	35103010: 'Health Care Technology',
	35201010: 'Biotechnology',
	35202010: 'Pharmaceuticals',
	35203010: 'Life Sciences Tools & Services',
	40101010: 'Diversified Banks',
	40101015: 'Regional Banks',
	40102010: 'Thrifts & Mortgage Finance',
	40201020: 'Other Diversified Financial Services',
	40201030: 'Multi-Sector Holdings',
	40201040: 'Specialized Finance',
	40202010: 'Consumer Finance',
	40203010: 'Asset Management & Custody Banks',
	40203020: 'Investment Banking & Brokerage',
	40203030: 'Diversified Capital Markets',
	40203040: 'Financial Exchanges & Data',
	40204010: 'Mortgage REITs',
	40301010: 'Insurance Brokers',
	40301020: 'Life & Health Insurance',
	40301030: 'Multi-line Insurance',
	40301040: 'Property & Casualty Insurance',
	40301050: 'Reinsurance',
	45102010: 'IT Consulting & Other Services',
	45102020: 'Data Processing & Outsourced Services',
	45102030: 'Internet Services & Infrastructure',
	45103010: 'Application Software',
	45103020: 'Systems Software',
	45201020: 'Communications Equipment',
	45202030: 'Technology Hardware, Storage & Peripherals',
	45203010: 'Electronic Equipment & Instruments',
	45203015: 'Electronic Components',
	45203020: 'Electronic Manufacturing Services',
	45203030: 'Technology Distributors',
	45301010: 'Semiconductor Equipment',
	45301020: 'Semiconductors',
	50101010: 'Alternative Carriers',
	50101020: 'Integrated Telecommunication Services',
	50102010: 'Wireless Telecommunication Services',
	50201010: 'Advertising',
	50201020: 'Broadcasting',
	50201030: 'Cable & Satellite',
	50201040: 'Publishing',
	50202010: 'Movies & Entertainment',
	50202020: 'Interactive Home Entertainment',
	50203010: 'Interactive Media & Services',
	55101010: 'Electric Utilities',
	55102010: 'Gas Utilities',
	55103010: 'Multi-Utilities',
	55104010: 'Water Utilities',
	55105010: 'Independent Power Producers & Energy Traders',
	55105020: 'Renewable Electricity',
	60101010: 'Diversified REITs',
	60101020: 'Industrial REITs',
	60101030: 'Hotel & Resort REITs',
	60101040: 'Office REITs',
	60101050: 'Health Care REITs',
	60101060: 'Residential REITs',
	60101070: 'Retail REITs',
	60101080: 'Specialized REITs',
	60102010: 'Diversified Real Estate Activities',
	60102020: 'Real Estate Operating Companies',
	60102030: 'Real Estate Development',
	60102040: 'Real Estate Services'
};

export const GICS_JA = {
	10: 'エネルギー',
	15: '素材',
	20: '資本財・サービス',
	25: '一般消費財・サービス',
	30: '生活必需品',
	35: 'ヘルスケア',
	40: '金融',
	45: '情報技術',
	50: 'コミュニケーション・サービス',
	55: '公益事業',
	60: '不動産',
	1010: 'エネルギー',
	1510: '素材',
	2010: '資本財',
	2020: '商業・専門サービス',
	2030: '運輸',
	2510: '自動車・自動車部品',
	2520: '耐久消費財・アパレル',
	2530: '消費者サービス',
	2550: '小売',
	3010: '食品・生活必需品小売り',
	3020: '食品・飲料・タバコ',
	3030: '家庭用品・パーソナル用品',
	3510: 'ヘルスケア機器・サービス',
	3520: '医薬品・バイオテクノロジー・ライフサイエンス',
	4010: '銀行',
	4020: '各種金融',
	4030: '保険',
	4510: 'ソフトウェア・サービス',
	4520: 'テクノロジー・ハードウェアおよび機器',
	4530: '半導体・半導体製造装置',
	5010: '電気通信サービス',
	5020: 'メディア・娯楽',
	5510: '公益事業',
	6010: '不動産',
	101010: 'エネルギー設備・サービス',
	101020: '石油・ガス・消耗燃料',
	151010: '化学',
	151020: '建設資材',
	151030: '容器・包装',
	151040: '金属・鉱業',
	151050: '紙製品・林産品',
	201010: '航空宇宙・防衛',
	201020: '建設関連製品',
	201030: '建設・土木',
	201040: '電気設備',
	201050: 'コングロマリット',
	201060: '機械',
	201070: '商社・流通業',
	202010: '商業サービス・用品',
	202020: '専門サービス',
	203010: '航空貨物・物流サービス',
	203020: '旅客航空輸送業',
	203030: '海運業',
	203040: '陸運・鉄道',
	203050: '運送インフラ',
	251010: '自動車部品',
	251020: '自動車',
	252010: '家庭用耐久財',
	252020: 'レジャー用品',
	252030: '繊維・アパレル・贅沢品',
	253010: 'ホテル・レストラン・レジャー',
	253020: '各種消費者サービス',
	255010: '販売',
	255020: 'インターネット販売・通信販売',
	255030: '複合小売り',
	255040: '専門小売り',
	301010: '食品・生活必需品小売り',
	302010: '飲料',
	302020: '食品',
	302030: 'タバコ',
	303010: '家庭用品',
	303020: 'パーソナル用品',
	351010: 'ヘルスケア機器・用品',
	351020: 'ヘルスケア・プロバイダー/ヘルスケア・サービス',
	351030: 'ヘルスケア・テクノロジー',
	352010: 'バイオテクノロジー',
	352020: '医薬品',
	352030: 'ライフサイエンス・ツール/サービス',
	401010: '銀行',
	401020: '貯蓄・抵当・不動産金融',
	402010: '各種金融サービス',
	402020: '消費者金融',
	402030: '資本市場',
	402040: 'モーゲージ不動産投資信託（REIT）',
	403010: '保険',
	451020: '情報技術サービス',
	451030: 'ソフトウェア',
	452010: '通信機器',
	452020: 'コンピュータ・周辺機器',
	452030: '電子装置・機器・部品',
	453010: '半導体・半導体製造装置',
	501010: '各種電気通信サービス',
	501020: '無線通信サービス',
	502010: 'メディア',
	502020: '娯楽',
	502030: 'インタラクティブ・メディアおよびサービス',
	551010: '電力',
	551020: 'ガス',
	551030: '総合公益事業',
	551040: '水道',
	551050: '独立系発電事業者・エネルギー販売業者',
	601010: 'エクイティ不動産投資信託　(REITs)',
	601020: '不動産管理・開発',
	10101010: '石油・ガス掘削',
	10101020: '石油・ガス装置・サービス',
	10102010: '総合石油・ガス',
	10102020: '石油・ガス探査・開発',
	10102030: '石油・ガス精製・販売',
	10102040: '石油・ガス貯蔵・輸送',
	10102050: '石炭・消耗燃料',
	15101010: '基礎化学品',
	15101020: '総合化学',
	15101030: '肥料・農薬',
	15101040: '工業用ガス',
	15101050: '特殊化学品',
	15102010: '建設資材',
	15103010: '金属・ガラス容器',
	15103020: '包装紙',
	15104010: 'アルミ',
	15104020: '各種金属・鉱業',
	15104025: '銅',
	15104030: '金',
	15104040: '貴金属・鉱物',
	15104045: '銀',
	15104050: '鉄鋼',
	15105010: '林産品',
	15105020: '紙製品',
	20101010: '航空宇宙・防衛',
	20102010: '建設関連製品',
	20103010: '建設・土木',
	20104010: '電気部品・設備',
	20104020: '重電機設備',
	20105010: 'コングロマリット',
	20106010: '建設機械・大型トラック',
	20106015: '農業機械',
	20106020: '産業機械',
	20107010: '商社・流通業',
	20201010: '商業印刷',
	20201050: '環境関連・ファシリティサービス',
	20201060: '事務サービス・用品',
	20201070: '各種支援サービス',
	20201080: 'セキュリティ・警報装置サービス',
	20202010: '人事・雇用サービス',
	20202020: '調査・コンサルティングサービス',
	20301010: '航空貨物・物流サービス',
	20302010: '旅客航空輸送業',
	20303010: '海運業',
	20304010: '鉄道',
	20304020: '陸運',
	20305010: '空港サービス',
	20305020: '高速道路・鉄道路線',
	20305030: '港湾サービス',
	25101010: '自動車部品・装置',
	25101020: 'タイヤ・ゴム',
	25102010: '自動車製造',
	25102020: '自動二輪車製造',
	25201010: '民生用電子機器',
	25201020: '家具・装飾',
	25201030: '住宅建設',
	25201040: '家庭用電気機器',
	25201050: '家庭用品・雑貨',
	25202010: 'レジャー用品',
	25203010: 'アパレル・アクセサリー・贅沢品',
	25203020: '履物',
	25203030: '繊維',
	25301010: 'カジノ・ゲーム',
	25301020: 'ホテル・リゾート・クルーズ船',
	25301030: 'レジャー設備',
	25301040: 'レストラン',
	25302010: '教育サービス',
	25302020: '専門消費者サービス',
	25501010: '販売',
	25502020: 'インターネット販売・通信販売',
	25503010: '百貨店',
	25503020: '総合小売り',
	25504010: '衣料小売り',
	25504020: 'コンピュータ・電子機器小売り',
	25504030: '住宅関連用品小売り',
	25504040: '専門店',
	25504050: '自動車小売り',
	25504060: '家具・装飾小売り',
	30101010: '薬品小売り',
	30101020: '食品流通',
	30101030: '食品小売り',
	30101040: '大型スーパーマーケット・スーパーマーケット',
	30201010: '醸造',
	30201020: '蒸留酒・ワイン',
	30201030: '清涼飲料',
	30202010: '農産物',
	30202030: '包装食品・肉',
	30203010: 'タバコ',
	30301010: '家庭用品',
	30302010: 'パーソナル用品',
	35101010: 'ヘルスケア機器',
	35101020: 'ヘルスケア用品',
	35102010: 'ヘルスケア・ディストリビュータ',
	35102015: 'ヘルスケアサービス',
	35102020: 'ヘルスケア施設',
	35102030: '管理健康医療',
	35103010: 'ヘルスケア・テクノロジー',
	35201010: 'バイオテクノロジー',
	35202010: '医薬品',
	35203010: 'ライフサイエンス・ツール/サービス',
	40101010: '都市銀行',
	40101015: '地方銀行',
	40102010: '貯蓄・抵当・不動産金融',
	40201020: ' 他の各種金融サービス',
	40201030: 'マルチセクター持株会社',
	40201040: '専門金融',
	40202010: '消費者金融',
	40203010: '資産運用会社・資産管理銀行',
	40203020: '投資銀行・証券会社',
	40203030: '総合資本市場',
	40203040: '取引所およびデータ提供会社',
	40204010: 'モーゲージ不動産投資信託（REIT）',
	40301010: '保険ブローカー',
	40301020: '生命保険・健康保険',
	40301030: '総合保険',
	40301040: '動産保険・損害保険',
	40301050: '再保険',
	45102010: '情報技術コンサルティング・他のサービス',
	45102020: '情報処理・外注サービス',
	45102030: 'インターネットサービスおよびインフラストラクチャー',
	45103010: 'アプリケーション・ソフトウェア',
	45103020: 'システム・ソフトウェア',
	45201020: '通信機器',
	45202030: 'テクノロジー ハードウェア・コンピュータ記憶装置・周辺機器',
	45203010: '電子装置・機器',
	45203015: '電子部品',
	45203020: '電子製品製造サービス',
	45203030: 'テクノロジー ディストリビュータ',
	45301010: '半導体装置',
	45301020: '半導体',
	50101010: '代替通信事業会社',
	50101020: '総合電気通信サービス',
	50102010: '無線通信サービス',
	50201010: '広告',
	50201020: '放送',
	50201030: 'ケーブル・衛星テレビ',
	50201040: '出版',
	50202010: '映画・娯楽',
	50202020: 'インタラクティブ・ホームエンターテイメント',
	50203010: 'インタラクティブ・メディアおよびサービス',
	55101010: '電力',
	55102010: 'ガス',
	55103010: '総合公益事業',
	55104010: '水道',
	55105010: '独立系発電事業者・エネルギー販売業者',
	55105020: '再生エネルギー系発電事業者',
	60101010: '各種不動産投資信託',
	60101020: '工業用不動産投資信託',
	60101030: 'ホテル・リゾート不動産投資信託',
	60101040: 'オフィス不動産投資信託',
	60101050: 'ヘルスケア不動産投資信託',
	60101060: '住宅用不動産投資信託',
	60101070: '店舗用不動産投資信託',
	60101080: '専門不動産投資信託',
	60102010: '各種不動産事業',
	60102020: '不動産運営会社',
	60102030: '不動産開発',
	60102040: '不動産サービス'
};
