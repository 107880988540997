<template>
	<v-text-field
		:value="value"
		@input="onInput"
		:label="label"
		:rules="rules"
		prepend-icon="mdi-vector-triangle"
		:counter="maxlen"
		:disabled="disabled"
		:clearable="clearable"
		autocomplete="off"
	/>
</template>
<script>
export default {
	props: {
		value: {
			type: String,
			default: '',
			required: false
		},
		label: {
			type: String,
			default: 'Department Name',
			required: false
		},
		minlen: {
			type: Number,
			default: 1,
			required: false
		},
		maxlen: {
			type: Number,
			default: 256,
			required: false
		},
		clearable: {
			type: Boolean,
			default: true,
			required: false
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	computed: {
		rules() {
			return [
				(v) =>
					!v ||
					v.length >= this.minlen ||
					this.$t('errors.minlen', { key: this.label, minlen: this.minlen }),
				(v) =>
					!v ||
					v.length <= this.maxlen ||
					this.$t('errors.maxlen', { key: this.label, maxlen: this.maxlen })
			];
		}
	},
	methods: {
		onInput(value) {
			this.$emit(`input`, value);
		}
	}
};
</script>
