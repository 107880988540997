<template>
	<v-container>
		<v-row>
			<v-col cols="12" sm="8" md="8" lg="8" xl="8" class="pa-1 pa-sm-3 pr-sm-1">
				<v-card elevation="1" class="ma-sm-3 mr-sm-0">
					<v-stepper v-model="step" alt-labels>
						<v-stepper-items>
							<v-stepper-content step="1" class="pa-0">
								<v-form v-model="valid" ref="form" @submit.prevent>
									<v-card-title class="pb-0 justify-space-between align-start">
										<div>{{ $t('organization_info.title') }}</div>
										<v-checkbox
											class="ma-0 pa-0"
											v-model="editable"
											off-icon="mdi-lock"
											on-icon="mdi-lock-open-variant"
										>
										</v-checkbox>
									</v-card-title>

									<v-card-text>
										<v-alert
											border="left"
											type="error"
											dense
											dismissible
											close-label="alert"
											class="body-2"
											:value="!!errorLabel"
											@input="errorLabel = null"
										>
											<span v-if="errorLabel"> {{ $t(`errors.${errorLabel}`) }} </span>
										</v-alert>
										<v-container>
											<OrganizationContent
												v-model="organization"
												:number-of-employees="numberOfEmployees"
												:disabled="!editable"
											/>
										</v-container>
									</v-card-text>

									<v-card-actions>
										<v-spacer />
										<v-btn
											color="primary"
											@click="submit"
											min-width="100px"
											:disabled="!valid"
											v-if="editable"
										>
											{{ $t('organization_info.submit') }}
										</v-btn>
									</v-card-actions>
								</v-form>
							</v-stepper-content>
							<v-stepper-content step="2" class="pa-0">
								<v-card-title> {{ $t('organization_info.success_title') }} </v-card-title>
								<v-card-text>
									{{ $t('organization_info.msg.success') }}
								</v-card-text>
								<v-card-actions>
									<v-spacer />
									<v-btn color="secondary" @click="setStep(1)" min-width="100px">
										{{ $t('organization_info.back') }}
									</v-btn>
								</v-card-actions>
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
				</v-card>
			</v-col>
			<v-col cols="12" md="4" lg="4" xl="4" class="pt-0 pa-1 pa-sm-3 pl-sm-1">
				<UploadOrganizationLogo
					:logo="organization.logo"
					@input="logoUpdated"
					:organizationId="organization.id"
					class="mb-sm-2 ma-sm-3 ml-sm-0"
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import validateOrganizationId from '@/lib/validate-organization-id';

import OrganizationContent from '@/components/OrganizationContent.vue';
import UploadOrganizationLogo from '@/components/UploadOrganizationLogo.vue';

export default {
	name: 'OrganizationInfo',
	components: {
		OrganizationContent,
		UploadOrganizationLogo
	},
	props: {
		id: {
			type: Number,
			required: false,
			default: undefined
		}
	},
	data: () => ({
		organization: {},
		numberOfEmployees: [],
		step: 1,
		valid: false,
		editable: false,
		errorLabel: null
	}),
	computed: {
		localStorageOrganizationId() {
			return this.$store.getters.localStorageOrganizationId;
		},
		organizations() {
			return this.$store.getters.organizations;
		}
	},
	watch: {
		async $route() {
			await this.init();
		}
	},
	async created() {
		await this.init();
	},
	methods: {
		async init() {
			try {
				const {
					data: { employees }
				} = await this.$axios.oidc.get(`/api/v2/private/employees`);
				employees.forEach((employee) => this.numberOfEmployees.push(employee));

				this.organization.id = validateOrganizationId(this.id);
				if (!this.organization.id) {
					if (
						this.localStorageOrganizationId &&
						this.organizations.some((org) => org.id === this.localStorageOrganizationId)
					) {
						this.$router.replace({
							params: { id: this.localStorageOrganizationId }
						});
						return;
					}
					this.$store.dispatch('clearLocalStorage');

					const [organization] = this.organizations;
					this.$router.replace({ params: { id: organization.id } });
					return;
				}

				const { data } = await this.$axios.oidc.get(`/api/v1/organization/${this.organization.id}`);
				this.organization = data;
				this.$emit('setSelectedOrganization', this.organization);
			} catch (error) {
				this.errorLabel = 'unknown';
			}
		},
		async submit() {
			const {
				createdAt,
				createdBy,
				id,
				numberOfEmployeeLabel,
				primaryOwnerAccountId,
				updatedAt,
				isPrivileged,
				...organization
			} = this.organization;

			try {
				const { data } = await this.$axios.oidc.patch(
					`/api/v1/organization/${this.organization.id}`,
					organization
				);
				this.organization = data;
				this.setStep(2);
			} catch (e) {
				this.errorLabel = 'unknown';
				switch (e.response?.status) {
					case 400:
						this.errorLabel = 'invalid_value';
						break;
					case 403:
						this.errorLabel = 'not_change_organization';
						break;
					case 404:
						this.errorLabel = 'not_found';
						break;
					default:
						break;
				}
			}
		},
		async logoUpdated(value) {
			this.organization.logo = value;

			const { data } = await this.$axios.oidc.get(`/api/v1/organization/${this.organization.id}`);
			this.organization = data;
			this.$emit('setSelectedOrganization', this.organization);
		},
		setStep(step) {
			this.step = step;
			if (step === 1) this.editable = false;

			this.$gtag.pageview(`${this.$route.path}?step=${step}`);
		}
	}
};
</script>
