<template>
	<div>
		<v-dialog v-model="dialog.register" persistent max-width="600px">
			<v-card>
				<v-card-title>
					{{ $t(`component.upload_organization_logo.dialog.upload_title`) }}
				</v-card-title>
				<v-card-text>
					<v-alert
						border="left"
						type="error"
						dense
						dismissible
						close-label="alert-upload"
						class="body-2"
						:value="!!errorLabel.upload"
						@input="errorLabel.upload = null"
					>
						<span v-if="errorLabel.upload">
							{{ $t(`component.upload_organization_logo.errors.${errorLabel.upload}`) }}
						</span>
					</v-alert>

					<v-row v-show="enabled" align="center">
						<v-col cols="8" align="center">
							<div style="width: 100%">
								<img alt="cropper" ref="cropper" :src="img.origin" @load.stop="createCropper" />
							</div>
						</v-col>
						<v-col cols="4" align="center">
							<OrganizationLogo :organization-logo="img.cropped" contain />
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="secondary" @click="dialog.register = false" min-width="100px">
						{{ $t('component.upload_organization_logo.cancel') }}</v-btn
					>
					<v-btn :loading="loading" color="primary" @click="save" min-width="100px">
						{{ $t(`component.upload_organization_logo.save`) }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialog.delete" persistent max-width="600px">
			<v-card>
				<v-card-title>
					{{ $t('component.upload_organization_logo.dialog.delete_title') }}
				</v-card-title>
				<v-card-text>
					<v-alert
						border="left"
						type="error"
						dense
						dismissible
						close-label="alert-delete"
						class="body-2"
						:value="!!errorLabel.delete"
						@input="errorLabel.delete = null"
					>
						<span v-if="errorLabel.delete">
							{{ $t(`component.upload_organization_logo.errors.${errorLabel.delete}`) }}
						</span>
					</v-alert>
					<OrganizationLogo class="d-flex justify-center" :organization-logo="logo" contain />
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="secondary" @click="dialog.delete = false" min-width="100px">
						{{ $t('component.upload_organization_logo.dialog.cancel') }}
					</v-btn>
					<v-btn color="error" @click="deleteImg" min-width="100px">
						{{ $t('component.upload_organization_logo.dialog.delete') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-card elevation="1">
			<v-card-title>{{ $t('component.upload_organization_logo.title') }}</v-card-title>
			<v-card-text>
				<v-alert
					border="left"
					type="error"
					dense
					dismissible
					close-label="alert-select"
					class="body-2"
					:value="!!errorLabel.select"
					@input="errorLabel.select = null"
				>
					<span v-if="errorLabel.select">
						{{ $t(`component.upload_organization_logo.errors.${errorLabel.select}`) }}
					</span>
				</v-alert>

				<!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
				<input
					:value="img.input"
					ref="selecter"
					class="d-none"
					type="file"
					accept="image/png, image/jpeg, image/gif"
					@input.stop="onFileInput"
				/>

				<OrganizationLogo class="d-flex justify-center" :organization-logo="logo" contain />
			</v-card-text>
			<v-card-actions class="d-flex justify-center">
				<v-btn class="ma-2" @click="onClickEditIcon" icon>
					<v-icon> mdi-pencil </v-icon>
				</v-btn>
				<v-btn class="ma-2" :disabled="!logo" @click="onClickDelIcon" icon>
					<v-icon> mdi-delete </v-icon>
				</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
import 'cropperjs/dist/cropper.min.css';
import Cropper from 'cropperjs';
import crypto from 'crypto';

import OrganizationLogo from '@/components/OrganizationLogo.vue';

export default {
	name: 'UploadOrganizationLogo',
	model: {
		prop: `logo`
	},
	props: {
		logo: {
			type: String,
			default: ''
		},
		organizationId: {
			type: Number,
			default: 0
		},
		maxImgSize: {
			type: Number,
			default: 1024 * 1024 * 1
		},
		avatorSize: {
			type: Number,
			default: 120
		}
	},
	components: {
		OrganizationLogo
	},
	data: () => ({
		maxSize: 2097152,
		img: {
			input: null,
			origin: null,
			cropped: null
		},
		cropper: null,
		dialog: {
			register: false,
			delete: false
		},
		enabled: false,
		loading: false,
		errorLabel: {
			select: null,
			upload: null,
			delete: null
		}
	}),
	computed: {
		size() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 80;
				default:
					return 125;
			}
		},
		minHeightWidth() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 200;
				default:
					return 300;
			}
		}
	},
	methods: {
		async save() {
			this.init();
			this.loading = true;

			const image = this.img.cropped.split(',', 2)[1];
			if (new TextEncoder().encode(image).length > this.maxSize) {
				this.errorLabel.upload = 'scroped_over_size';
				this.loading = false;
				return;
			}

			try {
				const { data: file } = await this.$axios.storage.post(`/api/v1/file/storage/public/image`, {
					...this.getMimeBase64ContType(this.img.cropped),
					image,
					md5hex: this.getMd5hex(image)
				});
				const {
					data: { logo }
				} = await this.$axios.oidc.patch(`/api/v1/organization/${this.organizationId}`, {
					logo: file.url
				});

				if (this.logo && this.logo !== logo)
					await this.$axios.storage.delete(`/api/v1/file/storage/public/image`, {
						data: { url: this.logo }
					});

				this.$emit('input', logo);
				this.dialog.register = false;
			} catch (e) {
				this.errorLabel.upload = 'unknown';
			} finally {
				this.loading = false;
			}
		},
		async deleteImg() {
			this.init();

			try {
				const {
					data: { logo }
				} = await this.$axios.oidc.patch(`/api/v1/organization/${this.organizationId}`, {
					logo: null
				});

				await this.$axios.storage.delete(`/api/v1/file/storage/public/image`, {
					data: { url: this.logo }
				});

				this.$emit('input', logo);
				this.dialog.delete = false;
			} catch (e) {
				this.errorLabel.delete = 'unknown';
			}
		},
		onClickEditIcon() {
			this.init();
			this.reset();
			this.$refs.selecter.click();
		},
		onClickDelIcon() {
			this.dialog.delete = true;
			this.errorLabel.delete = null;
		},
		async onFileInput(e) {
			const selectedImg = e.target.files[0];
			if (this.cropper) this.cropper.destroy();

			const reader = new FileReader();
			reader.onloadend = (rd) => {
				this.img.origin = rd.target.result;
				if (!this.getMimeBase64ContType(this.img.origin).contentType) {
					this.errorLabel.select = 'unsupported_extension';
					return;
				}
				if (selectedImg.size > this.maxImgSize) {
					this.errorLabel.select = 'over_size';
					return;
				}

				this.dialog.register = true;
			};
			reader.readAsDataURL(selectedImg);
		},
		createCropper() {
			this.cropper = new Cropper(this.$refs.cropper, {
				autoCropArea: 1,
				movable: false,
				zoomable: false,
				responsive: false,
				minContainerHeight: this.minHeightWidth,
				minContainerWidth: this.minHeightWidth,
				ready: () => {
					this.img.cropped = this.cropper.getCroppedCanvas().toDataURL();
					this.enabled = true;
				},
				cropend: () => {
					this.img.cropped = this.cropper.getCroppedCanvas().toDataURL();
				}
			});
		},
		init() {
			this.errorLabel = {
				select: null,
				upload: null,
				delete: null
			};
		},
		reset() {
			this.enabled = false;
			this.img.origin = null;
		},
		getMimeBase64ContType(base64file) {
			const file = base64file.substring(0, 128).split(',', 2)[1];
			switch (file.toUpperCase().substring(0, 5)) {
				case 'IVBOR':
					return { contentType: 'image/png' };
				case '/9J/4':
					return { contentType: 'image/jpeg' };
				case 'R0LGO':
					return { contentType: 'image/gif' };
				default:
					return { contentType: '' };
			}
		},
		getMd5hex(image) {
			const buffer = Buffer.from(image, 'base64');
			const md5hash = crypto.createHash('md5');
			md5hash.update(buffer);
			return md5hash.digest('hex');
		}
	}
};
</script>
