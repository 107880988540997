<template>
	<v-row>
		<v-col cols="12">
			<BusinessName
				:value="organization.name"
				@input="onInput('name', $event)"
				:disabled="disabled"
				:label="$t('organization.labels.name')"
				:hint="$t('organization.hints.name')"
				:tabindex="1"
			/>
		</v-col>
		<v-col cols="12">
			<DepartmentName
				:value="organization.department"
				@input="onInput('department', $event)"
				:disabled="disabled"
				:label="$t('organization.labels.department')"
				:tabindex="2"
			/>
		</v-col>
		<v-col cols="12" sm="6" md="6" lg="6" xl="6">
			<NumberOfEmployeesSelector
				:value="organization.numberOfEmployeeId"
				@input="onInput('numberOfEmployeeId', $event)"
				:number-of-employees="numberOfEmployees"
				:disabled="disabled"
				:label="$t('organization.labels.number_of_employees')"
				:tabindex="3"
			/>
		</v-col>
		<v-col cols="12" sm="6" md="6" lg="6" xl="6">
			<TelephoneNumber
				v-if="organization.countryCode || create"
				:value="organization.phoneNumber"
				@input="onInput('phoneNumber', $event)"
				:default-country="organization.countryCode"
				:only-countries="onlyCountries"
				:disabled="disabled"
				@country-changed="countryChanged"
				:tabindex="4"
			/>
		</v-col>
		<v-col cols="12">
			<IndustrySelector
				:industry-code="organization.industryCode"
				@input="onInput('industryCode', $event)"
				:validate-on-blur="validateOnBlurIndustrySelector"
				:disabled="disabled"
				:tabindex="5"
			/>
		</v-col>
		<v-col cols="12">
			<LinkInput
				:value="organization.website"
				@input="onInput('website', $event)"
				:disabled="disabled"
				:label="$t('organization.labels.website')"
				prepend-icon="mdi-link"
				:tabindex="6"
			/>
		</v-col>
	</v-row>
</template>

<script>
import BusinessName from '@/components/Input/BusinessName.vue';
import DepartmentName from '@/components/Input/DepartmentName.vue';
import TelephoneNumber from '@/components/Input/TelephoneNumber.vue';
import LinkInput from '@/components/Input/LinkInput.vue';
import NumberOfEmployeesSelector from '@/components/NumberOfEmployeesSelector.vue';
import IndustrySelector from '@/components/IndustrySelector.vue';

export default {
	name: 'OrganizationContent',
	model: {
		prop: `organization`
	},
	components: {
		BusinessName,
		DepartmentName,
		NumberOfEmployeesSelector,
		TelephoneNumber,
		IndustrySelector,
		LinkInput
	},
	props: {
		organization: {
			type: Object,
			default: () => ({
				name: '',
				departmentName: '',
				numberOfEmployeeId: null,
				phoneNumber: '',
				countryCode: '',
				industryCode: null,
				website: null
			}),
			required: false
		},
		numberOfEmployees: {
			type: Array,
			default: () => [],
			required: false
		},
		validateOnBlurIndustrySelector: {
			type: Boolean,
			default: false,
			required: false
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		create: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: () => ({
		valid: false,
		countryList: ['jp', 'cn', 'hk', 'kr', 'tw', 'us', 'vn']
	}),
	computed: {
		onlyCountries() {
			const onlyCountries = this.countryList.filter(
				(country) => country !== this.organization.countryCode
			);
			if (this.organization.countryCode) onlyCountries.unshift(this.organization.countryCode);
			return onlyCountries;
		}
	},
	methods: {
		onInput(key, value) {
			const emitValue = this.organization;
			emitValue[`${key}`] = value;
			this.$emit('input', emitValue);
		},
		countryChanged(value) {
			this.$emit('input', { ...this.organization, countryCode: value.iso2.toLowerCase() });
		}
	}
};
</script>
