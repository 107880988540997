import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';
import '@fontsource/roboto';
import colors from 'vuetify/lib/util/colors';
import i18n from '@/plugins/i18n';

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'mdiSvg'
	},
	theme: {
		themes: {
			light: {
				secondary: colors.grey.darken1
			}
		}
	},
	lang: {
		t: (key, ...params) => i18n.t(key, params)
	}
});
