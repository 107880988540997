<template>
	<v-container>
		<v-row class="justify-center">
			<v-col cols="12" ms="8" md="8" lg="8" xl="8" class="pa-1 pa-sm-3 pr-sm-1">
				<v-card elevation="1" class="ma-sm-3 mr-sm-0">
					<v-card-title> {{ $t('not_found.title') }} </v-card-title>
					<v-card-text>
						{{ $t('not_found.text') }}
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn color="primary" @click="back" min-width="100px">
							{{ $t('not_found.back') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'NotFound',
	computed: {
		organizations() {
			return this.$store.getters.organizations;
		},
		user() {
			return this.$store.getters.user;
		}
	},
	methods: {
		back() {
			if (this.organizations.length) this.$router.push({ path: `/info` });
			else this.$router.push({ path: `/new` });
		}
	}
};
</script>
