<template>
	<div>
		<v-img
			v-if="organizationLogo"
			:src="organizationLogo"
			:max-height="maxHeight"
			:max-width="maxWidth"
			:contain="contain"
		/>
		<v-avatar v-else>
			<v-icon :size="iconSize">{{ defaultIcon }}</v-icon>
		</v-avatar>
	</div>
</template>

<script>
export default {
	name: 'OrganizationLogo',
	model: {
		prop: `organizationLogo`
	},
	props: {
		organizationLogo: {
			type: String,
			default: '',
			require: false
		},
		maxHeight: {
			type: Number,
			default: 80,
			require: false
		},
		maxWidth: {
			type: Number,
			default: 440,
			require: false
		},
		iconSize: {
			type: Number,
			default: 40,
			require: false
		},
		defaultIcon: {
			type: String,
			default: 'mdi-dark mdi-domain',
			require: false
		},
		contain: {
			type: Boolean,
			default: false,
			required: false
		}
	}
};
</script>
