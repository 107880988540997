<template>
	<v-container>
		<v-row>
			<v-col cols="12" ms="8" md="8" lg="8" xl="8" class="pa-1 pa-sm-3 pr-sm-1">
				<v-card elevation="1" class="mt-1 ma-sm-3 mr-sm-0">
					<v-stepper v-model="step" alt-labels>
						<v-stepper-items>
							<v-stepper-content step="1" class="pa-0">
								<v-form v-model="valid" ref="form" @submit.prevent>
									<v-card-title> {{ $t('invite_org.invite_title') }} </v-card-title>
									<v-card-text>
										<v-row justify="center">
											<v-col cols="8" align="center" class="pa-0">
												<EmailInput
													v-model="newInvite.register"
													:label="$t('invite_org.email')"
													@blur="$refs.form.validate()"
													class="pl-4 pr-4"
												/>
											</v-col>
											<v-col cols="4" align="center" class="pa-0">
												<v-switch
													v-model="isPrivileged"
													:label="$t('invite_org.granting_privileged')"
													hide-details
													class="pl-4 pr-4"
												></v-switch>
											</v-col>
										</v-row>
									</v-card-text>
									<v-card-actions>
										<v-spacer />
										<v-btn color="primary" @click="setStep(2)" :disabled="!valid" min-width="100px">
											{{ $t('invite_org.invite') }}
										</v-btn>
									</v-card-actions>
								</v-form>
							</v-stepper-content>

							<v-stepper-content step="2" class="pa-0">
								<v-card-title> {{ $t('invite_org.title_invite_confirm') }} </v-card-title>
								<v-card-text>
									<v-alert
										border="left"
										type="error"
										dense
										dismissible
										close-label="alert-register"
										class="body-2"
										:value="!!errorLabel.register"
										@input="errorLabel.register = null"
									>
										<span v-if="errorLabel.register">
											{{ $t(`errors.${errorLabel.register}`) }}
										</span>
									</v-alert>
									{{ $t('invite_org.text_invite_confirm', { email: newInvite.register }) }}
								</v-card-text>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn color="secondary" @click="setStep(1)" min-width="100px">
										{{ $t('invite_org.disagree_label') }}
									</v-btn>
									<v-btn color="primary" @click="invite()" min-width="100px">
										{{ $t('invite_org.agree_label') }}
									</v-btn>
								</v-card-actions>
							</v-stepper-content>

							<v-stepper-content step="3" class="pa-0">
								<v-card-title> {{ $t('invite_org.invitation_complete') }} </v-card-title>
								<v-card-text>
									{{ $t('invite_org.msg.invited', { email: newInvite.register }) }}
								</v-card-text>
								<v-card-actions>
									<v-spacer />
									<v-btn color="primary" @click="setStep(1)" min-width="100px">
										{{ $t('invite_org.continue_invite') }}
									</v-btn>
								</v-card-actions>
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
				</v-card>

				<v-card elevation="1" class="ma-sm-3 mr-sm-0">
					<v-card-title> {{ $t('invite_org.invitating_title') }} </v-card-title>
					<v-card-text class="py-0">
						<v-alert
							border="left"
							type="error"
							dense
							dismissible
							close-label="alert-table"
							class="body-2"
							:value="!!errorLabel.table"
							@input="errorLabel.table = null"
						>
							<span v-if="errorLabel.table"> {{ $t(`errors.${errorLabel.table}`) }} </span>
						</v-alert>
						{{ $t('invite_org.msg.inviting_list_text') }}
						<v-data-table
							:headers="headers"
							:items="inviteLists"
							:items-per-page="10"
							mobile-breakpoint="960"
							class="elevation-0"
							item-key="id"
							:no-data-text="$t('invite_org.not_exist_invite')"
							:footer-props="footerProps"
						>
							<template #[`item.accepted`]="{ item }">
								<v-chip :color="convertStatusColor(item.accepted)" small outlined>
									{{ convertStatus(item.accepted) }}
								</v-chip>
							</template>
							<template #[`item.createdAt`]="{ item }">
								{{ formatedDate(item.createdAt) }}
							</template>
							<template #[`item.resend`]="{ item }">
								<v-icon
									@click.stop="confirmResend(item)"
									:disabled="isColumnDisabled(item.accepted)"
									class="elevation-0"
								>
									mdi-email-sync-outline
								</v-icon>
							</template>
							<template #[`item.cancel`]="{ item }">
								<v-chip
									class="elevation-2"
									color="error"
									:disabled="isColumnDisabled(item.accepted)"
									@click="confirmCancel(item)"
									small
								>
									{{ $t('invite_org.cancel_invite') }}
								</v-chip>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-dialog v-model="dialog.cancel" persistent max-width="600px">
			<v-card>
				<v-card-title> {{ $t('invite_org.dialog.title_cancel_confirm') }} </v-card-title>
				<v-card-text>
					{{ $t('invite_org.dialog.text_cancel_confirm', { email: newInvite.cancel }) }}
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="secondary" @click="dialog.cancel = false" min-width="100px">
						{{ $t('invite_org.dialog.disagree_label') }}
					</v-btn>
					<v-btn color="error" @click="cancelInvite" min-width="100px">
						{{ $t('invite_org.dialog.agree_label') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialog.resend" persistent max-width="600px">
			<v-card>
				<v-card-title> {{ $t('invite_org.dialog.title_resend_confirm') }} </v-card-title>
				<v-card-text>
					{{ $t('invite_org.dialog.text_resend_confirm', { email: newInvite.resend }) }}
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="secondary" @click="dialog.resend = false" min-width="100px">
						{{ $t('invite_org.dialog.disagree_label') }}
					</v-btn>
					<v-btn color="primary" @click="resendInvite" min-width="100px">
						{{ $t('invite_org.dialog.agree_label') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import moment from 'moment';
import validateOrganizationId from '@/lib/validate-organization-id';

import EmailInput from '@/components/Input/EmailInput.vue';

export default {
	name: 'InviteOrganization',
	components: {
		EmailInput
	},
	props: {
		id: {
			type: Number,
			required: false,
			default: undefined
		}
	},
	data: () => ({
		organization: {},
		inviteLists: [],
		inviteId: '',
		newInvite: {
			register: '',
			cancel: '',
			resend: '',
			isPrivileged: null
		},
		isPrivileged: false,
		step: 1,
		valid: false,
		dialog: {
			invite: false,
			cancel: false,
			resend: false
		},
		errorLabel: {
			table: null,
			register: null
		},
		counter: {
			incrementPerSeconds: 60,
			value: 0,
			intervalId: null
		}
	}),
	computed: {
		localStorageOrganizationId() {
			return this.$store.getters.localStorageOrganizationId;
		},
		organizations() {
			return this.$store.getters.organizations;
		},
		headers() {
			return [
				{
					text: this.$t('invite_org.status'),
					align: 'center',
					sortable: false,
					class: 'px-0',
					value: 'accepted'
				},
				{
					text: this.$t('invite_org.invite_date'),
					align: 'center',
					sortable: false,
					class: 'px-0',
					value: 'createdAt'
				},
				{
					text: this.$t('invite_org.inviter'),
					align: 'center',
					sortable: false,
					class: 'px-0',
					value: 'inviterName'
				},
				{
					text: this.$t('invite_org.invite_email'),
					align: 'center',
					sortable: false,
					class: 'px-0',
					value: 'email'
				},
				{
					text: this.$t('invite_org.resend'),
					align: 'center',
					sortable: false,
					class: 'px-0',
					value: 'resend'
				},
				{
					text: this.$t('invite_org.cancel_invite_title'),
					align: 'center',
					sortable: false,
					class: 'px-0',
					value: 'cancel'
				}
			];
		},
		convertStatus() {
			return (accepted) => {
				if (accepted === null) return this.$t('invite_org.status_unsupported');
				return accepted
					? this.$t('invite_org.status_accepted')
					: this.$t('invite_org.status_rejected');
			};
		},
		convertStatusColor() {
			return (accepted) => {
				if (accepted === null) return 'secondary';
				return accepted ? 'primary' : 'error';
			};
		},
		formatedDate() {
			// eslint-disable-next-line no-unused-expressions
			this.counter.value;
			return (createdAt) => {
				const momentDate = moment.unix(createdAt);
				if (moment().diff(momentDate, 'seconds') < 60)
					return `< ${momentDate.locale(this.$i18n.locale).fromNow()}`;
				return momentDate.locale(this.$i18n.locale).fromNow();
			};
		},
		footerProps() {
			return {
				itemsPerPageOptions: [10, 50, 100]
			};
		},
		isColumnDisabled() {
			return (accepted) => accepted !== null;
		}
	},
	watch: {
		async $route() {
			await this.setInviteEmail();
		}
	},
	async created() {
		this.configureMoment();
		this.setIntervalIncrementCounter();
		await this.setInviteEmail();
	},
	destroyed() {
		clearInterval(this.counter.intervalId);
	},
	methods: {
		async getInvites() {
			const {
				data: { invites }
			} = await this.$axios.oidc.get(`/api/v1/organization/${this.organization.id}/invites`);
			this.inviteLists = invites;
		},
		async setInviteEmail() {
			try {
				this.organization.id = validateOrganizationId(this.id);
				if (!this.organization.id) {
					if (
						this.localStorageOrganizationId &&
						this.organizations.some((org) => org.id === this.localStorageOrganizationId)
					) {
						this.$router.replace({
							params: { id: this.localStorageOrganizationId }
						});
						return;
					}
					this.$store.dispatch('clearLocalStorage');

					const [organization] = this.organizations;
					this.$router.replace({ params: { id: organization.id } });
					return;
				}

				await this.getInvites();
				const { data } = await this.$axios.oidc.get(`/api/v1/organization/${this.organization.id}`);
				const selectedOrganization = data;
				this.$emit('setSelectedOrganization', selectedOrganization);
			} catch (e) {
				this.errorLabel.table = 'unknown';
				switch (e.response?.status) {
					case 404:
						this.errorLabel.table = 'not_found_user';
						break;
					default:
				}
			}
		},
		async invite() {
			this.resetError();

			await this.$refs.form.validate();
			if (!this.valid) return;

			try {
				await this.$axios.oidc.post(`api/v1/organization/${this.organization.id}/invite`, {
					isPrivileged: this.isPrivileged,
					email: this.newInvite.register,
					notification: { webview: true, webpush: true, sendmail: true }
				});
				this.setStep(3);
				this.isPrivileged = false;
				await this.getInvites();
			} catch (e) {
				this.errorLabel.register = 'unknown';
				switch (e.response.status) {
					case 403:
						this.errorLabel.register = 'not_privileged';
						break;
					case 404:
						this.errorLabel.register = 'not_found_user';
						break;
					case 409:
						this.errorLabel.register = 'inviting_email';
						break;
					default:
				}
			}
		},
		async resendInvite() {
			try {
				this.dialog.resend = false;
				await this.$axios.oidc.post(`api/v1/organization/${this.organization.id}/invite`, {
					isPrivileged: this.newInvite.isPrivileged,
					email: this.newInvite.resend,
					notification: { webview: true, webpush: true, sendmail: true },
					resend: true
				});
			} catch (e) {
				this.errorLabel.table = 'unknown';
				switch (e.response.status) {
					case 403:
						this.errorLabel.table = 'not_privileged';
						break;
					case 404:
						this.errorLabel.table = 'not_found_user';
						break;
					case 409:
						this.errorLabel.table = 'inviting_email';
						break;
					default:
				}
			}
		},
		async cancelInvite() {
			this.resetError();
			this.dialog.cancel = false;
			try {
				await this.$axios.oidc.delete(
					`/api/v1/organization/${this.organization.id}/invite/${this.inviteId}`
				);
				await this.getInvites();
			} catch (e) {
				this.errorLabel.table = 'unknown';
				switch (e.response.status) {
					case 403:
						this.errorLabel.table = 'not_privileged';
						break;
					case 404:
						this.errorLabel.table = 'invite_email_not_found';
						break;
					default:
				}
			}
		},
		setIntervalIncrementCounter() {
			this.counter.intervalId = setInterval(() => {
				this.counter.value += 1;
			}, this.counter.incrementPerSeconds * 1000);
		},
		configureMoment() {
			moment.relativeTimeThreshold('s', 0);
			moment.updateLocale('en', {
				relativeTime: {
					m: 'mins',
					mm: '%d mins'
				}
			});
		},
		resetError() {
			this.errorLabel = { table: null, register: null };
		},
		confirmCancel(item) {
			this.resetError();
			const { id, email } = item;
			this.dialog.cancel = true;
			this.newInvite.cancel = email;
			this.inviteId = id;
		},
		confirmResend(item) {
			this.resetError();
			const { id, email, isPrivileged } = item;
			this.dialog.resend = true;
			this.newInvite.resend = email;
			this.newInvite.isPrivileged = isPrivileged;
			this.inviteId = id;
		},
		setStep(step) {
			this.step = step;
			if (step === 1) this.newInvite.register = '';
			if (step === 2) this.resetError();

			this.$gtag.pageview(`${this.$route.path}?step=${step}`);
		}
	}
};
</script>
<style lang="sass">
.v-data-table
	.v-data-table__wrapper
		tr
			td
				font-size: 0.70em !important
				padding: 0 3px !important
			th
				font-size: 0.6em !important
				padding: 0 3px !important
</style>
