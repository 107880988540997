import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import * as Cookies from 'js-cookie';
import validateOrganizationId from '@/lib/validate-organization-id';

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		accountToken: null,
		user: {},
		organizations: [],
		localStorageOrganizationId: null
	},
	getters: {
		user: (state) => state.user,
		organizations: (state) => state.organizations,
		localStorageOrganizationId: (state) => validateOrganizationId(state.localStorageOrganizationId)
	},
	mutations: {
		initAccountToken(state, accountToken) {
			state.accountToken = accountToken;
		},
		setUser(state, user) {
			state.user = user;
		},
		setOrganizations(state, organizations) {
			state.organizations = organizations;
		},
		setLocalStorageOrganizationId(state, localStorageOrganizationId) {
			state.localStorageOrganizationId = localStorageOrganizationId;
		}
	},
	actions: {
		initAccountToken({ commit }, accountToken) {
			commit('initAccountToken', accountToken);
		},
		setUser({ commit }, user) {
			commit('setUser', user);
		},
		setOrganizations({ commit }, organizations) {
			commit('setOrganizations', organizations);
		},
		loadLocalStorage({ commit }) {
			if (localStorage.getItem('oid'))
				commit('setLocalStorageOrganizationId', localStorage.getItem('oid'));
		},
		saveLocalStorage({ commit }, localStorageOrganizationId) {
			commit('setLocalStorageOrganizationId', localStorageOrganizationId);
			localStorage.setItem('oid', localStorageOrganizationId);
		},
		clearLocalStorage({ commit }) {
			commit('setLocalStorageOrganizationId', null);
			localStorage.removeItem('oid');
		}
	},
	modules: {},
	plugins: [
		createPersistedState({
			key: 'session',
			storage: {
				getItem: (key) => {
					const accountToken = Cookies.get(key);
					if (!accountToken) return undefined;
					return JSON.stringify({ accountToken });
				},
				setItem: (key, value) => {
					const data = JSON.parse(value);
					const params = { secure: false };
					Cookies.set(key, data.accountToken, params);
				},
				removeItem: (key) => Cookies.remove(key)
			}
		})
	]
});

export default store;
