<template>
	<div>
		<vue-tel-input-vuetify
			:value="value"
			@input="onInput"
			@country-changed="countryChanged"
			mode="international"
			autocomplete="off"
			disabled-fetching-country
			single-line
			:rules="rules"
			:label="$t('organization.labels.phone_number')"
			:clearable="clearable"
			:disabled="disabled"
			:maxLen="maxLen"
			:default-country="defaultCountry"
			:selectLabel="$t('organization.labels.country')"
			:only-countries="onlyCountries"
			:placeholder="$t('organization.labels.phone_number')"
		/>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			default: '',
			required: true
		},
		maxLen: {
			type: Number,
			default: 32,
			required: false
		},
		onlyCountries: {
			type: Array,
			default: () => [],
			required: false
		},
		defaultCountry: {
			type: String,
			default: null,
			required: false
		},
		clearable: {
			type: Boolean,
			default: true,
			required: false
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data: () => ({
		valid: true
	}),
	computed: {
		rules() {
			return [
				(v) =>
					!!v || this.$t('errors.required', { key: this.$t('organization.labels.phone_number') }),
				(v) =>
					(v && this.valid) ||
					this.$t('errors.valid', { key: this.$t('organization.labels.phone_number') })
			];
		}
	},
	methods: {
		onInput(formattedNumber, { number, valid }) {
			this.valid = valid;
			this.$emit(`input`, number.international);
		},
		countryChanged(value) {
			this.$emit(`country-changed`, value);
		}
	}
};
</script>
