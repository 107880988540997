<template>
	<div id="industries-tree">
		<v-text-field
			prepend-icon="mdi-factory"
			readonly
			:value="selected.industry"
			@input="onInput"
			:label="$t('labels.industry')"
			@click="dialog = true"
			:hint="hint"
			persistent-hint
			@click:clear="clear"
			:clearable="clearable"
			:required="required"
			:rules="rules"
			:disabled="disabled"
			:validate-on-blur="validateOnBlur"
		/>

		<v-dialog v-model="dialog" scrollable max-width="520px">
			<v-card>
				<v-card-title>{{ $t('labels.select_industry') }}</v-card-title>
				<v-divider />
				<v-card-text style="height: 400px">
					<v-treeview
						item-disabled="locked"
						:search="search"
						:items="items"
						:open.sync="openedIds"
						:active="activeItems"
						activatable
						open-on-click
						@update:active="actived"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions justify="end">
					<v-text-field
						prepend-icon="mdi-magnify"
						v-model="search"
						:label="$t('labels.search_industry')"
						clearable
						hide-details
						dense
						solo
						flat
					/>
					<v-spacer />
					<v-btn color="blue darken-1" text @click="dialog = false">
						{{ $t('labels.close') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import INDUSTRIES from '@/constants/industories';
import { GICS_EN, GICS_JA } from '@/constants/gics';

export default {
	model: {
		prop: `industryCode`
	},
	data: () => ({
		dialog: false,
		search: null,
		items: [],
		selected: {
			item: null,
			industry: null
		},
		openedIds: [],
		activeItems: [],
		codeSplitPoint: [2, 4, 6]
	}),
	props: {
		industryCode: {
			type: Number,
			default: null,
			required: false
		},
		required: {
			type: Boolean,
			default: true,
			required: false
		},
		clearable: {
			type: Boolean,
			default: true,
			required: false
		},
		validateOnBlur: {
			type: Boolean,
			default: false,
			required: false
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	i18n: {
		messages: {
			en: {
				gics: GICS_EN,
				labels: {
					industry: 'Industry',
					close: 'CLOSE',
					select_industry: 'Select Industry',
					search_industry: 'Search Industry'
				}
			},
			ja: {
				gics: GICS_JA,
				labels: {
					industry: '業種',
					close: '閉じる',
					select_industry: '業種を選択',
					search_industry: '業種を検索'
				}
			}
		}
	},
	created() {
		this.init();
	},
	watch: {
		'$i18n.locale': {
			async handler() {
				this.localize();
				if (this.selected.item) this.selected.industry = this.$t(`gics.${this.selected.item}`);
			}
		},
		industryCode() {
			this.init();
		}
	},
	computed: {
		rules() {
			return [
				(v) => this.dialog || !!v || this.$t('errors.required', { key: this.$t('labels.industry') })
			];
		},
		hint() {
			if (!this.selected.industry) return null;
			return `GICS:${this.selected.item}`;
		}
	},
	methods: {
		init() {
			this.items = this.industry2tree(INDUSTRIES);
			this.localize();

			if (this.industryCode) {
				this.selected.item = this.industryCode;
				this.selected.industry = this.$t(`gics.${this.selected.item}`);

				this.codeSplitPoint.forEach((point) =>
					this.openedIds.push(this.industryCode.toString().substr(0, point))
				);
				this.activeItems = [this.industryCode];
			}
		},
		clear() {
			this.selected.item = null;
			this.selected.industry = null;
			this.activeItems = [];
			this.$emit(`input`, this.selected.item);
		},
		actived(item) {
			if (item.length) {
				[this.selected.item] = item;
				this.selected.industry = this.$t(`gics.${this.selected.item}`);
				this.$emit(`input`, this.selected.item);
				return;
			}
			this.clear();
		},
		industry2tree(industriesList) {
			const treeObjects = [];
			const sectors = {};
			const industryGroups = {};
			const industries = {};

			industriesList.sort().forEach((key) => {
				const sector = key.toString().substr(0, 2);
				const industryGroup = key.toString().substr(0, 4);
				const industry = key.toString().substr(0, 6);
				let children = treeObjects;
				if (!(sector in sectors)) {
					children.push({
						id: sector,
						children: []
					});
					sectors[sector] = children.length - 1;
				}
				children = children[sectors[sector]].children;

				if (!(industryGroup in industryGroups)) {
					children.push({
						id: industryGroup,
						children: []
					});
					industryGroups[industryGroup] = children.length - 1;
				}
				children = children[industryGroups[industryGroup]].children;

				if (!(industry in industries)) {
					children.push({
						id: industry,
						children: []
					});
					industries[industry] = children.length - 1;
				}
				children = children[industries[industry]].children;
				children.push({
					id: key
				});
			});
			return treeObjects;
		},
		async localize() {
			const setI18n = async (items) => {
				Promise.all(
					items.map(async (item) => {
						// 更新を強制通知
						this.$set(item, 'name', this.$t(`gics.${item.id}`));
						if (item.children) {
							await setI18n(item.children);
						}
					})
				);
			};
			await setI18n(this.items);
			return this.items;
		},
		onInput(value) {
			this.$emit(`input`, value);
		}
	}
};
</script>
