<template>
	<v-container>
		<v-row>
			<v-col cols="12" ms="8" md="8" lg="8" xl="8" class="pa-1 pa-sm-3 pr-sm-1">
				<v-card elevation="1" class="ma-sm-3 mr-sm-0">
					<v-card-title> {{ $t('organization_users.title') }} </v-card-title>
					<v-card-text>
						<v-alert
							border="left"
							type="error"
							dense
							dismissible
							close-label="alert"
							class="body-2"
							:value="!!errorLabel"
							@input="errorLabel = null"
						>
							<span v-if="errorLabel"> {{ $t(`errors.${errorLabel}`) }} </span>
						</v-alert>

						<v-data-table
							:headers="headers"
							:items="organizationUsers"
							:loading="loading"
							:options.sync="options"
							:server-items-length="total"
							@update:options="updateOptions"
							:footer-props="footerProps"
							:no-data-text="$t('organization_users.table.no_data_text')"
							:no-results-text="$t('organization_users.table.no_result_text')"
							:mobile-breakpoint="960"
						>
							<template #top>
								<v-text-field
									:value="searchQuery"
									@input="input"
									prepend-icon="mdi-magnify"
									:label="$t('organization_users.table.search.label')"
									:hint="$t('organization_users.table.search.hint')"
									dense
									clearable
								></v-text-field>
							</template>

							<template #[`item.name`]="{ item }">
								<v-avatar :size="24" class="mr-2">
									<v-img v-if="item.picture" :src="item.picture" />
									<v-icon v-else> mdi-account-circle </v-icon>
								</v-avatar>
								<span>{{ item.name }}</span>
							</template>

							<template #[`item.privilege`]="{ item }">
								<v-chip
									:color="chipColor(item.accountId, item.isPrivileged)"
									small
									outlined
									:disabled="disabled(item.accountId)"
									@click="confirmChangingPrivilege(item)"
								>
									{{ $t(chipText(item.accountId, item.isPrivileged)) }}
								</v-chip>
							</template>

							<template #[`item.lastLoginAt`]="{ item }">
								{{ formatedDate(item.lastLoginAt) }}
							</template>

							<template #[`item.createdAt`]="{ item }">
								{{ formatedDate(item.createdAt) }}
							</template>

							<template #[`item.delete`]="{ item }">
								<v-icon @click="confirmDelete(item)" :disabled="disabled(item.accountId)">
									mdi-account-remove
								</v-icon>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12" md="4" lg="4" xl="4" class="pa-1 pa-sm-3 pl-sm-1">
				<div class="ma-sm-3 ml-sm-0">
					<v-expansion-panels accordion outlined elevation="0" flat class="faq">
						<h3>{{ $t('organization_users.faq.title') }}</h3>
						<v-expansion-panel v-for="faq in faqs" :key="faq" class="grey lighten-5">
							<div style="border-bottom: 1px solid #ccc">
								<v-expansion-panel-header>
									{{ $t(`organization_users.faq.headers.${faq}`) }}
								</v-expansion-panel-header>
								<v-expansion-panel-content style="white-space: pre-wrap">
									{{ $t(`organization_users.faq.items.${faq}`) }}
								</v-expansion-panel-content>
							</div>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
			</v-col>
		</v-row>

		<v-dialog v-model="dialog.delete" persistent max-width="600px">
			<v-card>
				<v-card-title> {{ $t('organization_users.dialog.delete.title') }} </v-card-title>
				<v-card-text>
					{{
						$t('organization_users.dialog.delete.text', {
							name: deletingUser.name,
							organizationName: deletingUser.organizationName
						})
					}}
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="secondary" @click="dialog.delete = false" min-width="100px">
						{{ $t('organization_users.dialog.delete.cancel') }}
					</v-btn>
					<v-btn color="error" @click="deleteUser" min-width="100px">
						{{ $t('organization_users.dialog.delete.delete') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog.privilege" persistent max-width="600px">
			<v-card>
				<v-system-bar>
					<v-spacer></v-spacer>
					<v-icon @click="dialog.privilege = false">mdi-close</v-icon>
				</v-system-bar>

				<v-card-title> {{ $t('organization_users.dialog.privilege.title') }} </v-card-title>
				<v-card-text>
					{{ $t('organization_users.dialog.privilege.text', { name: changingUser.name }) }}
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						:color="changingUser.isPrivileged ? 'error' : 'primary'"
						@click="changePrivilege"
						min-width="100px"
					>
						{{
							changingUser.isPrivileged
								? $t('organization_users.dialog.privilege.to_member')
								: $t('organization_users.dialog.privilege.to_privilege')
						}}
					</v-btn>
					<v-btn
						v-if="isPrimaryOwner"
						color="success"
						@click="changePrimaryOwner"
						min-width="100px"
					>
						{{ $t('organization_users.dialog.privilege.to_primary_owner') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import validateOrganizationId from '@/lib/validate-organization-id';

export default {
	name: 'OrganizationUsers',
	props: {
		id: {
			type: Number,
			required: false,
			default: undefined
		}
	},
	data: () => ({
		organization: {},
		organizationUsers: [],
		loading: true,
		total: 0,
		options: {},
		deletingUser: {},
		changingUser: {},
		searchQuery: '',
		faqs: [
			'what_member',
			'what_privilege',
			'what_primary_owner',
			'regarding_primary_owner_transfer'
		],
		errorLabel: null,
		dialog: {
			delete: false,
			privilege: false
		},
		counter: {
			incrementPerSeconds: 60,
			value: 0,
			intervalId: null
		}
	}),
	computed: {
		localStorageOrganizationId() {
			return this.$store.getters.localStorageOrganizationId;
		},
		user() {
			return this.$store.getters.user;
		},
		organizations() {
			return this.$store.getters.organizations;
		},
		headers() {
			return [
				{
					text: this.$t('organization_users.table.header.name'),
					sortable: false,
					value: 'name'
				},
				{
					text: this.$t('organization_users.table.header.email'),
					sortable: false,
					value: 'email'
				},
				{
					text: this.$t('organization_users.table.header.privilege'),
					align: 'center',
					sortable: false,
					value: 'privilege'
				},
				{
					text: this.$t('organization_users.table.header.last_login_at'),
					align: 'center',
					sortable: false,
					value: 'lastLoginAt'
				},
				{
					text: this.$t('organization_users.table.header.created_at'),
					align: 'center',
					sortable: false,
					value: 'createdAt'
				},
				{
					text: this.$t('organization_users.table.header.delete'),
					align: 'center',
					sortable: false,
					value: 'delete'
				}
			];
		},
		footerProps() {
			return {
				itemsPerPageOptions: [10, 50, 100]
			};
		},
		formatedDate() {
			// eslint-disable-next-line no-unused-expressions
			this.counter.value;
			return (createdAt) => {
				const momentDate = moment.unix(createdAt);
				if (moment().diff(momentDate, 'seconds') < 60)
					return `< ${momentDate.locale(this.$i18n.locale).fromNow()}`;
				return momentDate.locale(this.$i18n.locale).fromNow();
			};
		},
		isPrimaryOwner() {
			return this.user.accountId === this.organization.primaryOwnerAccountId;
		},
		disabled() {
			return (accountId) =>
				accountId === this.user.accountId || accountId === this.organization.primaryOwnerAccountId;
		},
		chipColor() {
			return (accountId, isPrivileged) => {
				if (accountId === this.organization.primaryOwnerAccountId) return 'success';
				if (isPrivileged) return 'primary';
				return 'error';
			};
		},
		chipText() {
			return (accountId, isPrivileged) => {
				if (accountId === this.organization.primaryOwnerAccountId)
					return 'organization_users.table.body.owner';
				if (isPrivileged) return 'organization_users.table.body.privilege';
				return 'organization_users.table.body.member';
			};
		}
	},
	watch: {
		async $route() {
			await this.init();
		}
	},
	async created() {
		this.configureMoment();
		this.setIntervalIncrementCounter();
		await this.init();
	},
	destroyed() {
		clearInterval(this.counter.intervalId);
	},
	// https://github.com/vuejs/vue-rx
	// https://rxjs.dev/api
	subscriptions() {
		return {
			_: this.$watchAsObservable('searchQuery').pipe(
				debounceTime(300),
				distinctUntilChanged(),
				map(async (vueWatchObj) => {
					try {
						await this.getOrganizationUsers(vueWatchObj.newValue);
					} catch (e) {
						this.errorLabel = 'unknown';
					} finally {
						this.loading = false;
					}
				})
			)
		};
	},
	methods: {
		async getOrganizationUsers(newValue) {
			if (newValue) this.options.page = 1;
			const { page, itemsPerPage } = this.options;

			if (this.organization.id && page) {
				const params = {};
				params.limit = itemsPerPage || 10;

				if (page > 1) params.offset = (page - 1) * itemsPerPage;
				if (this.searchQuery) params.query = this.searchQuery;

				const {
					data: { total, organizationUsers }
				} = await this.$axios.oidc.get(`/api/v1/organization/${this.organization.id}/users`, {
					params
				});
				this.total = total;
				this.organizationUsers = organizationUsers;
			}
		},
		async init() {
			this.loading = true;
			try {
				this.organization.id = validateOrganizationId(this.id);
				if (!this.organization.id) {
					if (
						this.localStorageOrganizationId &&
						this.organizations.some((org) => org.id === this.localStorageOrganizationId)
					) {
						this.$router.replace({
							params: { id: this.localStorageOrganizationId }
						});
						return;
					}
					this.$store.dispatch('clearLocalStorage');

					const [organization] = this.organizations;
					this.$router.replace({ params: { id: organization.id } });
					return;
				}

				await this.getOrganizationUsers();
				const { data } = await this.$axios.oidc.get(`/api/v1/organization/${this.organization.id}`);
				this.organization = data;
				this.$emit('setSelectedOrganization', this.organization);
			} catch (e) {
				this.errorLabel = 'unknown';
			} finally {
				this.loading = false;
			}
		},
		async updateOptions() {
			this.loading = true;
			this.errorLabel = null;

			try {
				await this.getOrganizationUsers();
			} catch (e) {
				this.errorLabel = 'unknown';
			} finally {
				this.loading = false;
			}
		},
		async deleteUser() {
			try {
				await this.$axios.oidc.delete(
					`/api/v1/organization/${this.organization.id}/user/${this.deletingUser.id}`
				);
				await this.getOrganizationUsers();
			} catch (e) {
				this.errorLabel = 'unknown';
				switch (e.response?.status) {
					case 403:
						this.errorLabel = 'not_delete';
						break;
					case 404:
						this.errorLabel = 'not_belog_organization';
						break;
					default:
				}
			} finally {
				this.dialog.delete = false;
			}
		},
		async changePrivilege() {
			try {
				await this.$axios.oidc.patch(
					`/api/v1/organization/${this.organization.id}/user/${this.changingUser.id}`,
					{
						isPrivileged: !this.changingUser.isPrivileged
					}
				);
				await this.getOrganizationUsers();
			} catch (e) {
				this.errorLabel = 'unknown';
				switch (e.response?.status) {
					case 403:
						this.errorLabel = 'not_change_privilege';
						break;
					default:
				}
			} finally {
				this.dialog.privilege = false;
			}
		},
		async changePrimaryOwner() {
			try {
				const { data } = await this.$axios.oidc.patch(
					`/api/v1/organization/${this.organization.id}`,
					{
						primaryOwnerAccountId: this.changingUser.accountId
					}
				);
				this.organization = data;
				this.$emit('setSelectedOrganization', this.organization);
				await this.getOrganizationUsers();

				const {
					data: { organizations }
				} = await this.$axios.oidc.get(`/api/v1/organizations`, {
					params: {
						only_privileged: true
					}
				});
				this.$store.dispatch('setOrganizations', organizations);
			} catch (e) {
				this.errorLabel = 'unknown';
				switch (e.response?.status) {
					case 403:
						this.errorLabel = 'must_be_primary_owner';
						break;
					case 409:
						this.errorLabel = 'not_specify_yourself_as_primary_owner';
						break;
					default:
				}
			} finally {
				this.dialog.privilege = false;
			}
		},
		setIntervalIncrementCounter() {
			this.counter.intervalId = setInterval(() => {
				this.counter.value += 1;
			}, this.counter.incrementPerSeconds * 1000);
		},
		configureMoment() {
			moment.relativeTimeThreshold('s', 0);
			moment.updateLocale('en', {
				relativeTime: {
					m: 'mins',
					mm: '%d mins'
				}
			});
		},
		input($event) {
			this.searchQuery = $event;
			this.loading = true;
			this.errorLabel = null;
		},
		confirmDelete(user) {
			this.errorLabel = null;
			this.dialog.delete = true;
			this.deletingUser = cloneDeep(user);
		},
		confirmChangingPrivilege(user) {
			this.errorLabel = null;
			this.dialog.privilege = true;
			this.changingUser = cloneDeep(user);
		}
	}
};
</script>
<style lang="sass" scoped>
.faq
	justify-content: left !important
	h3
		font-size: 1rem
		color: #666
		font-weight: 500
	.v-expansion-panel-header
		padding: 16px 0
		color: #666
		font-size: 0.8rem
		font-weight: 500
	.v-expansion-panel-content
		&::v-deep
			.v-expansion-panel-content__wrap
				padding: 0 0 16px 0 !important
		color: #999
		font-size: 0.8rem
</style>
