<template>
	<v-app>
		<NavigationHeader
			:selectedOrganization="selectedOrganization"
			:disabled-menu="
				this.$route.name === 'new' ||
				this.$route.name === 'notFound' ||
				this.$route.name === 'noauth'
			"
			@switchSelectedOrganization="switchSelectedOrganization"
		/>
		<v-main v-if="!loading" class="grey lighten-5">
			<router-view @setSelectedOrganization="setSelectedOrganization" />
		</v-main>
		<NavigationFooter />
	</v-app>
</template>

<script>
import NavigationHeader from '@/components/NavigationHeader.vue';
import NavigationFooter from '@/components/NavigationFooter.vue';

export default {
	name: 'HomeView',
	components: {
		NavigationHeader,
		NavigationFooter
	},
	data: () => ({
		selectedOrganization: {},
		loading: true
	}),
	async created() {
		this.loading = false;
	},
	methods: {
		setSelectedOrganization(organization) {
			this.selectedOrganization = organization;
		},
		async switchSelectedOrganization(organization) {
			this.selectedOrganization = organization;
			this.$router.replace({ params: { id: organization.id } });
			this.$store.dispatch('saveLocalStorage', organization.id);
		}
	}
};
</script>
